import React, {useEffect, useLayoutEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Redirect, useParams} from 'react-router-dom';
import {
    Container,
    Divider,
    Header,
    Grid,
    Card,
    Icon, Checkbox, Segment, Button, Label
} from 'semantic-ui-react';
import moment from 'moment';
import {childrenGet, userGet, userInvite, clearError} from "../../actions/user";
import EmptyBoard from '../empty-board';
import {alertsGet, alertsSetActioned} from "../../actions/alerts";
import styled from "styled-components";
import {simplifyId} from "../../utils/ui";
import {hasAccess} from "../../utils/protected-component/protected-component";

const SegmentStyled = styled(Segment)`
  &&& {
    background-color: #F8F8F9;
  }
`;

const ManageAlerts = (props) => {
    const { type } = useParams();
    const defaultFilter = [ 'tamper', 'geofence', 'belowthreshold', 'endoflife' ];

    let [checked, setChecked] = useState([]);
    let [filter, setFilter] = useState(type ? [type] : defaultFilter);
    let [ skip, setSkip ] = useState(0);

    useLayoutEffect(() => {
        if(skip === 0) {
            props.userGet();
            props.alertsGet({ types: filter, ignoreLoad: false, scrollLoading: false, skip: 0 } || []);
        }

        window.addEventListener('scroll', handleScroll, true);

        return () => window.removeEventListener('scroll', handleScroll, true)
    }, [filter, props.alerts.alerts.length]);

    useEffect(() => {
        if (checked.length > 0) {
            checked.forEach(id => {
                if (props.alerts.alerts.find(x => x._id === id) && !props.alerts.alerts.find(x => x._id === id).unread) setChecked(checked.filter(check => check !== id));
            });
        }
    });

    const checkAlert = (e) => {
        e.stopPropagation();
        const id = e.target.id;
        const index = checked.length > 0 ? checked.findIndex(x => x === id) : -1;
        if (index === -1) {
            setChecked([...checked, id]);
        } else {
            setChecked(checked.filter(check => check !== id));
        }
    };

    const isChecked = (id) => {
        const index = checked.length > 0 ? checked.findIndex(x => x === id) : -1;
        return index !== -1
    };

    const allChecked = () => {
        return props.alerts.alerts.filter(item => item.unread ? item._id : null).map(item => item._id).length;
    };

    const checkAll = (e) => {
        if (checked.length === allChecked()) {
            setChecked([]);
        } else {
            setChecked(props.alerts.alerts.filter(item => item.unread ? item._id : null).map(item => item._id));
        }
    };

    const isLoading = (id) => {
        return (props.alerts.actioningAlert === id) || (isChecked(id) && props.alerts.actioningAlert === true);
    };

    const actionAlerts = (alertId) => {
        if (props.alerts.actioningAlert) return;

        let payload = {
            filter: filter.length === 3 ? [] : filter
        };

        if (alertId) {
            payload.ids = [alertId];
        } else {
            payload.ids = checked;
        }

        if (payload.ids && payload.ids.length > 0) props.actionAlerts(payload);
    };

    const isFiltered = (name) => {
        return filter.find(f => f === name);
    };

    const isFilteredColor = (name) => {
        return isFiltered(name) ? 'green' : 'grey';
    };

    const handleFilter = (name) => {
        // let types;

        if(name === 'all') {
            setFilter(defaultFilter);
            props.alertsGet([]);
            // return;
        } else {
            setFilter([name]);
            props.alertsGet([name]);
        }

        // const index = filter.length > 0 ? filter.findIndex(x => x === name) : -1;
        // if (index === -1) {
        //     types = [...filter, name];
        // } else {
        //     if(filter.length === 1) return;
        //     types = filter.filter(check => check !== name);
        // }
        //
        // setFilter(types);
        // props.alertsGet(types.length === 3 ? [] : types);
    };

    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target;

        if (offsetHeight + scrollTop === scrollHeight) {
            setSkip(skip+20);
            props.alertsGet({ types: filter, ignoreLoad: true, scrollLoading: true, skip: props.alerts.alerts.length + 20 })
        }
    };

    return (
        <Container fluid>
            <Divider hidden/>
            <Container >
                <Card.Group itemsPerRow={5} centered stackable >
                    <Card color={isFilteredColor('tamper')} raised onClick={() => handleFilter('tamper')}>
                        <Card.Content textAlign='center'>
                            <Icon color={isFilteredColor('tamper')} name='eye' />
                            <span style={{ color: isFilteredColor('tamper')}}>Tamper</span>
                        </Card.Content>
                    </Card>
                    <Card color={isFilteredColor('geofence')} raised  onClick={() => handleFilter('geofence')}>
                        <Card.Content textAlign='center'>
                            <Icon color={isFilteredColor('geofence')} name='map marker alternate'  />
                            <span style={{ color: isFilteredColor('geofence')}}>Geofence</span>
                        </Card.Content>
                    </Card>
                    <Card color={isFilteredColor('belowthreshold')} raised  onClick={() => handleFilter('belowthreshold')}>
                        <Card.Content textAlign='center'>
                            <Icon.Group>
                                <Icon name='battery three quarters' color={isFilteredColor('belowthreshold')} />
                                <Icon corner name='exclamation' color={isFilteredColor('belowthreshold')} />
                            </Icon.Group>
                            <span style={{ color: isFilteredColor('battery')}}>Low Wakeups</span>
                        </Card.Content>
                    </Card>
                    <Card color={isFilteredColor('endoflife')} raised  onClick={() => handleFilter('endoflife')}>
                        <Card.Content textAlign='center'>
                            <Icon.Group>
                                <Icon name='battery three quarters' color={isFilteredColor('endoflife')} />
                                <Icon corner name='x' color={isFilteredColor('endoflife')} />
                            </Icon.Group>
                            <span style={{ color: isFilteredColor('endoflife')}}>End of Life</span>
                        </Card.Content>
                    </Card>
                    <Card color={filter.length === 3 ? 'green' : 'grey'}  onClick={() => handleFilter('all')}>
                        <Card.Content textAlign='center'>
                            <Icon color={filter.length === 3 ? 'green' : 'grey'} name='alarm'  />
                            <span style={{ color: filter.length === 3 ? 'green' : 'grey'}}>All Alerts</span>
                        </Card.Content>
                    </Card>
                </Card.Group>
            </Container>

            <Container>
                <Segment basic>
                    <Grid columns={2}>
                        <Grid.Column>
                            <Segment basic compact floated='left' clearing><Checkbox
                                checked={checked.length === allChecked() && checked.length > 0}
                                onChange={checkAll}/></Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment basic compact floated='right' clearing>
                                <Button icon='check' color='green' loading={props.alerts.actioningAlert === true}
                                        disabled={!(checked.length >= 2)} onClick={() => actionAlerts()}/>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Container>

            <Container>
                {!props.alerts.isLoadingAlerts && (props.alerts.alerts && props.alerts.alerts.length > 0) ?
                    alertsList({...props.alerts, checkAlert, isChecked, actionAlerts, isLoading})
                    : <EmptyBoard loader={props.alerts.isLoadingAlerts} icon='alarm' message='You have no alerts.'/>}
            </Container>
            <Divider hidden/>
            {props.alerts.scrollLoading ? <Container><Segment loading={props.alerts.scrollLoading} /></Container> : null}
            <Divider hidden/>

        </Container>
    );
};

const alertsList = (props) => {
    const alerts = [];

    props.alerts.forEach((alert, i) => {
        const AlertCardFilled = (innerProps) => (
            <AlertCard
                id={alert._id}
                dateTime={alert.dateTime}
                checkAlert={props.checkAlert}
                isChecked={props.isChecked}
                actionAlerts={props.actionAlerts}
                isLoading={props.isLoading}
                unread={alert.unread}
                color={innerProps.color}
                icon={innerProps.icon}
                iconOverride={innerProps.iconOverride}
                header={innerProps.header}
                description={innerProps.description}
            />
        );

        const combineNameID = (deviceName, deviceId) => {
            if(!deviceName.trim()) return simplifyId(deviceId);

            return `(${deviceName}) ${simplifyId(deviceId)}`;
        }

        const identifier = combineNameID(alert.deviceData.name, alert.deviceId) // (alert.deviceData && alert.deviceData.name) || simplifyId(alert.deviceId)
        switch (alert.type) {
            case 'tamper':
                alerts.push(
                    <AlertCardFilled
                        key={i + alert._id}
                        icon='alarm'
                        color='red'
                        header={`Tamper Alert for ${identifier}`}
                        description={<span>A tamper alert was activated for <b>{identifier}</b> at the following location: <b>{alert.location}</b></span>}
                    />);
                break;
            case 'battery':
                alerts.push(
                    <AlertCardFilled
                        key={i + alert._id}
                        icon='battery low'
                        color='red'
                        header={` Battery Alert for ${identifier}`}
                        description={<span>The battery level for <b>{identifier}</b> is running low: <span
                            style={{color: 'red'}}><b>{alert.batteryLevel}%</b></span></span>}
                    />);
                break;
            case 'belowthreshold':
                alerts.push(
                    <AlertCardFilled
                        key={i + alert._id}
                        iconOverride={<Icon.Group>
                            <Icon name='battery three quarters' color='yellow' />
                            <Icon corner name='exclamation' color='red' />
                        </Icon.Group>}
                        color='red'
                        header={` Wakeups Running Low Alert for ${identifier}`}
                        description={<span>The number of wakeups remaining for <b>{identifier}</b> is running low and the device will soon need replacing.</span>}
                    />);
                break;
            case 'endoflife':
                alerts.push(
                    <AlertCardFilled
                        key={i + alert._id}
                        iconOverride={<Icon.Group>
                            <Icon name='battery three quarters' color='red' />
                            <Icon corner name='x' color='red' />
                        </Icon.Group>}
                        color='red'
                        header={` End of Life Alert for ${identifier}`}
                        description={<span>The device <b>{identifier}</b> has exceeded the number of guaranteed wake ups and will now need replacing.</span>}
                    />);
                break;
            case 'geofence':
                alerts.push(
                    <AlertCardFilled
                        key={i + alert._id}
                        icon='map marker alternate'
                        color='red'
                        header={`Geofence Alert for ${identifier}`}
                        description={
                            alert.detail && alert.detail.inside ?
                                <span>A geofence alert was generated when <b>{identifier}</b> entered the geofence <b>{alert.detail && alert.detail.geofenceName ? alert.detail.geofenceName : 'Untitled Geofence'}</b> at the following location: <b>{alert.location}</b></span> :
                                <span>A geofence alert was generated when <b>{identifier}</b> left the geofence <b>{alert.detail && alert.detail.geofenceName ? alert.detail.geofenceName : 'Untitled Geofence'}</b> and is now at the following location: <b>{alert.location}</b></span>
                        }

                    />);
                break;
            default:
                break;
            // default:
            //     alerts.push(<BatteryCard key={i + alert._id} dateTime={alert.dateTime} batteryLevel={alert.batteryLevel}  name={alert.deviceData.name}/>);
            //     break;
        }
    });

    return alerts;
};

const AlertCard = (props) => (
    <Card fluid color={props.unread ? 'red' : null}>
        <Card.Content>
            <Segment basic floated='right' clearing>{props.unread ?
                <Button icon='check' color='green' loading={props.isLoading(props.id)}
                        onClick={() => props.actionAlerts(props.id)}/> : null}</Segment>
            <Segment basic floated='left' clearing>{props.unread ?
                <Checkbox id={props.id} onChange={props.checkAlert} checked={props.isChecked(props.id)} fitted/> :
                <span>&nbsp;</span>}</Segment>
            <Card.Header>
                {!props.iconOverride ? <Icon name={props.icon} color={props.color} disabled={!props.unread}/> : null }
                {props.iconOverride ? props.iconOverride : null}
                <span style={props.unread ? {} : {fontWeight: 'normal'}}>{props.header}</span>
            </Card.Header>

            <Card.Content>
                <Card.Meta>
                    <span className='date'>{moment(props.dateTime).format('dddd DD-MM-YYYY [@]HH:mm')}</span>
                </Card.Meta>
                <Divider hidden/>
                <Card.Description>
                    {props.description}
                </Card.Description>
            </Card.Content>
        </Card.Content>
    </Card>
);

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
        groups: state.groups,
        alerts: state.alerts
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        childrenGet: () => dispatch(childrenGet()),
        userAdd: (payload) => dispatch(userInvite(payload)),
        userGet: () => dispatch(userGet()),
        clearErrors: () => dispatch(clearError()),
        alertsGet: (payload) => dispatch(alertsGet(payload)),
        actionAlerts: (payload) => dispatch(alertsSetActioned(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAlerts);
