import {
    GEOFENCES_ADD_REQUEST,
    GEOFENCES_UPDATE_REQUEST,
    GEOFENCES_ADD_DEVICE_REQUEST,
    GEOFENCES_GET_REQUEST,
    GEOFENCES_GET_SUCCESS,
    GEOFENCES_GET_FAILURE,
    GEOFENCES_SAVE_REQUEST,
    GEOFENCES_SAVE_SUCCESS,
    GEOFENCES_SAVE_FAILURE,
    GEOFENCES_DEVICES_REQUEST,
    GEOFENCES_DEVICES_SUCCESS,
    GEOFENCES_DEVICES_FAILURE,
    GEOFENCES_SAVE_DEVICES_REQUEST,
    GEOFENCES_REMOVE_UNSAVED_REQUEST, LOGOUT
} from '../actiontypes/types';

const initialState = {
    geofences: [],
    original: [],
    geofenceDevices: {},
    geofenceGroups: {},
    geofenceDeviceCount: {},
    // geofences: [
    //     {
    //         type: 'rectangle',
    //         name: 'MEV',
    //         inside: true,
    //         detail: {
    //             path: [{"latitude":53.40032,"longitude":-2.19043},{"latitude":53.40022,"longitude":-2.17},{"latitude":53.37995,"longitude":-2.17},{"latitude":53.38015,"longitude":-2.19025}]
    //         },
    //         devices: []
    //     },
    //     {
    //         type: 'polygon',
    //         name: 'Tim Triangle',
    //         inside: true,
    //         detail: {
    //             path: [{"latitude":50.94703,"longitude":-1.38192},{"latitude":50.94186,"longitude":-1.39728},{"latitude":50.94065,"longitude":-1.37831}]
    //         },
    //         devices: []
    //     },
    //     {
    //         type: 'circle',
    //         name: 'MEV Circle',
    //         inside: true,
    //         detail: {
    //             center: {
    //                 "latitude": 53.34649208153577,
    //                 "longitude": -2.156863698578513
    //             },
    //             radius: 334.9487657497693
    //         },
    //         devices: []
    //     }
    // ],
    isLoadingGeofences: false,
    isSavingGeofences: false,
    isSavingGeofenceDevices: false
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case GEOFENCES_GET_REQUEST:
            return {
                ...state,
                isLoadingGeofences: true
            };
        case GEOFENCES_GET_SUCCESS:
            return {
                ...state,
                geofences: payload.geofences,
                original: JSON.parse(JSON.stringify(payload.geofences)),
                isLoadingGeofences: false
            };
        case GEOFENCES_GET_FAILURE:
            return {
                ...state,
                error: payload,
                isLoadingGeofences: false
            };
        case GEOFENCES_SAVE_REQUEST:
            return {
                ...state,
                isSavingGeofences: true
            };
        case GEOFENCES_SAVE_SUCCESS:
            return {
                ...state,
                isSavingGeofences: false
            };
        case GEOFENCES_SAVE_FAILURE:
            return {
                ...state,
                error: payload,
                isSavingGeofences: false
            };
        case GEOFENCES_DEVICES_REQUEST:
            return {
                ...state
            };
        case GEOFENCES_SAVE_DEVICES_REQUEST:
            return {
                ...state,
                isSavingGeofenceDevices: true
            };
        case GEOFENCES_DEVICES_SUCCESS:
            const geofencesDevices = state.geofences.map(x => {
                if(x._id === payload.geofenceId) return { ...x, devices: payload.devices, groups: payload.groups };
                return x;
            });

            const geofencesDevicesOrig = state.original.map(x => {
                if(x._id === payload.geofenceId) return { ...x, devices: JSON.parse(JSON.stringify(payload.devices)), groups: JSON.parse(JSON.stringify(payload.groups)) }
                return x;
            });

            const groupsNotInDevices = (payload.groupDevices && payload.groupDevices.filter(item1 => !payload.devices.some(item2 => item1 === item2)).length) || 0;
            const deviceNotInGroups = payload.devices.length;

            return {
                ...state,
                geofences: geofencesDevices,
                original: geofencesDevicesOrig,
                geofenceDevices: { ...state.geofenceDevices, [payload.geofenceId]: payload.devices},
                geofenceGroups: { ...state.geofenceGroups, [payload.geofenceId]: payload.groups},
                geofenceDeviceCount: { ...state.geofenceDeviceCount, [payload.geofenceId]: deviceNotInGroups + groupsNotInDevices},
                isSavingGeofenceDevices: false
            };
        case GEOFENCES_DEVICES_FAILURE:
            return {
                ...state,
                error: payload,
                isSavingGeofenceDevices: false
            };
        case GEOFENCES_UPDATE_REQUEST:
            const geofences = state.geofences;

            if(geofences[payload.index] && payload.detail === true) {
                geofences[payload.index].detail[payload.property] = payload.value;
            }

            if(geofences[payload.index] && payload.meta === true) {
                geofences[payload.index][payload.property] = payload.value;
            }

            if(geofences[payload.index]._id && !payload.revert) geofences[payload.index].changed = true;

            if(payload.revert) geofences[payload.index] = JSON.parse(JSON.stringify(payload.value));

            return {
                ...state,
                geofences
            };
        case GEOFENCES_ADD_REQUEST:
            return {
                ...state,
                geofences: [ ...state.geofences, payload ]
            };
        case GEOFENCES_REMOVE_UNSAVED_REQUEST:
            return {
                ...state,
                geofences: state.geofences.filter(geofence => geofence._id)
            };
        case GEOFENCES_ADD_DEVICE_REQUEST:
            // If it doesn't exist, add it, otherwise remove it
            const geofenceDevices = state.geofences;
            const index = geofenceDevices[payload.index].devices ? geofenceDevices[payload.index].devices.findIndex(x => x === payload.id) : -1;
            if(index === -1) {
                if(!geofenceDevices[payload.index].devices) geofenceDevices[payload.index].devices = [];
                geofenceDevices[payload.index].devices.push(payload.id);
            } else {
                geofenceDevices[payload.index].devices = (geofenceDevices[payload.index].devices.filter(item => item !== payload.id));
            }
            return {
                ...state,
                geofences: geofenceDevices
            };
        case LOGOUT:
            return {
                ...initialState,
            };

        default: return state;
    }
};
