import React from 'react';
import Moment from "moment";
import {Popup} from "semantic-ui-react";

export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const simplifyId = (id) => {
    if (!id || (id && id.length < 10)) {
        return id;
    }
    const newId =
        id.slice(id.length - 10, id.length - 5) +
        '-' +
        id.slice(id.length - 5, id.length);

    return newId;
};

export const validDeviceId = (id) => {
    const re = /^-?\d+\.?\d*$/;
    return !re.test(String(id));
}

export const isHibernateTimezone = () => {
    const time = Moment();
    const utcOffset = time.utcOffset();
    const isDST = time.isDST();

    if(isDST && (utcOffset > 0 && utcOffset <= 120)) return true;
    if(!isDST && (utcOffset >= 0 && utcOffset < 120)) return true;
    return false;
}

export const truncate = (text) => {
    return text && text.length > 10 ? <Popup
        inverted
        trigger={<span>{text.substr(0, 10) + '...'}</span>}
        content={text}
        position='bottom left'
    /> : text
};

const MAX_WAKEUPS = 1095;
export const getWakeupBalance = (wakeCount) => {
    return wakeCount ? MAX_WAKEUPS - wakeCount : MAX_WAKEUPS;
}
