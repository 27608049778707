import {
    GEOFENCES_UPDATE_REQUEST,
    GEOFENCES_UPDATE_SUCCESS,
    GEOFENCES_ADD_SUCCESS,
    GEOFENCES_ADD_REQUEST,
    GEOFENCES_ADD_DEVICE_REQUEST,
    GEOFENCES_GET_REQUEST,
    GEOFENCES_GET_SUCCESS,
    GEOFENCES_GET_FAILURE,
    GEOFENCES_SAVE_REQUEST,
    GEOFENCES_SAVE_SUCCESS,
    GEOFENCES_SAVE_FAILURE,
    GEOFENCES_REMOVE_REQUEST,
    GEOFENCES_REMOVE_SUCCESS,
    GEOFENCES_REMOVE_FAILURE,
    GEOFENCES_DEVICES_REQUEST,
    GEOFENCES_DEVICES_SUCCESS,
    GEOFENCES_DEVICES_FAILURE,
    GEOFENCES_SAVE_DEVICES_REQUEST,
    GEOFENCES_REMOVE_UNSAVED_REQUEST

} from '../actiontypes/types';

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

export const addGeofenceRequest = (payload) => {
    return {
        type: GEOFENCES_ADD_REQUEST,
        payload
    }
};

export const updateGeofenceRequest = (payload) => {
    return {
        type: GEOFENCES_UPDATE_REQUEST,
        payload
    }
};

export const removeUnsavedGeofences = () => {
    return {
        type: GEOFENCES_REMOVE_UNSAVED_REQUEST
    }
};

export const updateGeofenceDeviceRequest = (payload) => {
    return {
        type: GEOFENCES_ADD_DEVICE_REQUEST,
        payload
    }

};

export const getGeofencesRequest = () => {
    return {
        type: GEOFENCES_GET_REQUEST
    }
};

export const getGeofencesSuccess = (payload) => {
    return {
        type: GEOFENCES_GET_SUCCESS,
        payload
    }
};

export const getGeofencesFailure = (payload) => {
    return {
        type: GEOFENCES_GET_FAILURE,
        payload
    }
};

export const geofencesGet = () => {
    return (dispatch) => {
        dispatch(getGeofencesRequest());

        return fetch(`${ZZZAPI}/api/geofences`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(getGeofencesSuccess(json))
            })
            .catch(err => {
                dispatch(getGeofencesFailure(err.message));
            });
    }
};

export const getGeofenceDevicesRequest = () => {
    return {
        type: GEOFENCES_DEVICES_REQUEST
    }
};

export const getGeofenceDevicesSuccess = (payload) => {
    return {
        type: GEOFENCES_DEVICES_SUCCESS,
        payload
    }
};

export const getGeofenceDevicesFailure = (payload) => {
    return {
        type: GEOFENCES_DEVICES_FAILURE,
        payload
    }
};

export const geofencesDevicesGet = (geofenceId) => {
    return (dispatch) => {
        if(!geofenceId) return;
        dispatch(getGeofenceDevicesRequest());

        return fetch(`${ZZZAPI}/api/geofences/devices/${geofenceId}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(getGeofenceDevicesSuccess(json))
            })
            .catch(err => {
                dispatch(getGeofenceDevicesFailure(err.message));
            });
    }
};

export const saveGeofencesRequest = () => {
    return {
        type: GEOFENCES_SAVE_REQUEST
    }
};

export const saveGeofencesSuccess = (payload) => {
    return {
        type: GEOFENCES_SAVE_SUCCESS,
        payload
    }
};

export const saveGeofencesFailure = (payload) => {
    return {
        type: GEOFENCES_SAVE_FAILURE,
        payload
    }
};

export const geofencesSave = (payload) => {
    const { selectedGeofenceId, ...postBody } = payload;
    return (dispatch) => {
        dispatch(saveGeofencesRequest());

        return fetch(`${ZZZAPI}/api/geofences`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(postBody)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(saveGeofencesSuccess());
                dispatch(geofencesGet());
                dispatch(geofencesDevicesGet(selectedGeofenceId));
            })
            .catch(err => {
                dispatch(saveGeofencesFailure(err.message));
            });
    }
};

export const removeGeofencesRequest = () => {
    return {
        type: GEOFENCES_REMOVE_REQUEST
    }
};

export const removeGeofencesSuccess = (payload) => {
    return {
        type: GEOFENCES_REMOVE_SUCCESS,
        payload
    }
};

export const removeGeofencesFailure = (payload) => {
    return {
        type: GEOFENCES_REMOVE_FAILURE,
        payload
    }
};

export const geofencesRemove = (payload) => {
    const postBody = { geofenceIds: payload };
    return (dispatch) => {
        dispatch(removeGeofencesRequest());

        return fetch(`${ZZZAPI}/api/geofences`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(postBody)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(removeGeofencesSuccess());
                dispatch(geofencesGet());
            })
            .catch(err => {
                dispatch(removeGeofencesFailure(err.message));
            });
    }
};

export const updateGeofenceDevicesRequest = () => {
    return {
        type: GEOFENCES_SAVE_DEVICES_REQUEST
    }
};

export const updateGeofenceDevicesSuccess = (payload) => {
    return {
        type: GEOFENCES_DEVICES_SUCCESS,
        payload
    }
};

export const updateGeofenceDevicesFailure = (payload) => {
    return {
        type: GEOFENCES_DEVICES_FAILURE,
        payload
    }
};

export const saveGeofenceDevices = (payload) => {
    const { selectedGeofenceId, ...postBody } = payload;
    return (dispatch) => {
        dispatch(updateGeofenceDevicesRequest());

        return fetch(`${ZZZAPI}/api/geofences/devices`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(postBody)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(updateGeofenceDevicesSuccess(json));
                console.log('PAYLOAD', payload);
                dispatch(geofencesDevicesGet(selectedGeofenceId));
            })
            .catch(err => {
                dispatch(updateGeofenceDevicesFailure(err.message));
            });
    }
};