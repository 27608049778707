import React, {useState, useEffect, useLayoutEffect} from 'react';
import {connect} from 'react-redux';
import {Menu, List, Grid, Button, Container, Divider, Checkbox } from 'semantic-ui-react';
import {geofencesDevicesGet, saveGeofenceDevices} from '../../actions/geofences';
import {simplifyId} from "../../utils/ui";

const GeofenceAssignDevices = (props) => {
    const [activeItem, setActiveItem] = useState(props.activeItem || 'devices');
    const [checked, setChecked] = useState({devices: props.deviceList || [], groups: props.groupList || []});
    const [ saving, setSaving ] = useState(false);
    const [ geofenceId, setGeofenceId ] = useState(null);

    useEffect(() => {
        if(!props.geofenceId || (geofenceId && geofenceId !== props.geofenceId)) {
            setGeofenceId(null);
            if(props.cancel) props.cancel();
        }

        // The order here is important, in reality the props.isSaving === true would occur first where the saving flag is set to true
        // Once the isSaving flag is false and the saving flag has been set to true, it will clear the is saving and allow progression to the next step
        if(saving && !props.geofences.isSavingGeofenceDevices) {
            setSaving(false);

            // Get the devices again now they've been saved
            props.geofencesDevicesGet(props.geofenceId);

            setGeofenceId(null);

            if(props.cancel) props.cancel();
        }

        // console.log('DEVICES: ', props.deviceList);

        if(props.geofences.isSavingGeofenceDevices) {
            setSaving(true);
        }

        if(props.geofenceId) setGeofenceId(props.geofenceId);

        // if(checked.devices !== props.devices) setChecked(props.devices);
    });

    const handleMenuClick = (e, {name}) => {
        setActiveItem(name);
    };

    const handleCheck = (e, { name, _id }) => {
        e.stopPropagation();
        const index = checked[name] && checked[name].length > 0 ? checked[name].findIndex(x => x === _id) : -1;
        if (index === -1) {
            setChecked({...checked, [name]: [...checked[name], _id]});
        } else {
            setChecked({...checked, [name]: checked[name].filter(check => check !== _id)});
        }

    };

    const isChecked = (name, id) => {
        const index = checked[name] && checked[name].length > 0 ? checked[name].findIndex(x => x === id) : -1;
        return index !== -1
    };

    const deviceList = props.devices.devices.map((device, i) => {
        const inGroup = isChecked('groups', device.groupId);
        const group = props.groups.groups.find(group => group._id === device.groupId);
        const groupName = group ? group.name : '';
        const name = !inGroup ? device.name : `${device.name || simplifyId(device._id)} -- (Added From Group: ${groupName})`
        return <List.Item key={'ass-' + device.name + i}>
            <Checkbox
                _id={device._id}
                name='devices'
                checked={isChecked('devices', device._id) || inGroup}
                label={name || simplifyId(device._id)}
                onChange={handleCheck}
            />
        </List.Item>
    });

    const groupsList = props.groups.groups.map((group, i) => {
        return <List.Item key={'ass-' + group.name + i}>
                    <Checkbox
                        _id={group._id}
                        name='groups'
                        checked={isChecked('groups', group._id)}
                        label={group.name}
                        onChange={handleCheck}
                    />
        </List.Item>
    });

    const changeItems = (originalList, updatedList, geofenceId) => {
        const changedItems = [];

        const add = [];
        const remove = [];

        // If there are no original items and no new items, do nothing
        if((!originalList || originalList.length === 0) && updatedList.length === 0 ) return;

        // If no original items but there are new items, just add them
        if((!originalList || originalList.length === 0) && updatedList.length > 0) {
            add.push(...updatedList);
        }

        // If there are original items but no new items, then remove all the old items
        if((originalList && originalList.length > 0) && updatedList.length === 0) {
            remove.push(...originalList);
        }

        // This should be the last condition for devices: check old devices for any that had been removed or added vs new devices array
        if ((originalList && originalList.length > 0) && (updatedList.length > 0)) {
            add.push(...updatedList.filter(item1 => !originalList.some(item2 => item1 === item2)));
            remove.push(...originalList.filter(item1 => !updatedList.some(item2 => item1 === item2)));
        }

        // Nothing added to add or remove, then do nothing
        if((!add || add.length === 0) && (!remove || remove.length === 0)) return;

        changedItems.push(
            {
                add,
                remove,
                geofenceId
            }
        );

        return changedItems;
    };

    const assignDevices = () => {
        // If we don't have a geofence ID, do nothing as there is nothing to save to
        if(!props.geofenceId) return;

        const changedDevices = changeItems(props.originalDeviceList, checked.devices, props.geofenceId);
        const changedGroups = changeItems(props.originalGroupList, checked.groups, props.geofenceId);

        if(!changedDevices && !changedGroups) return;

        // Save the changed devices to the geofence
        if((changedDevices && changedDevices.length > 0) || (changedGroups && changedGroups.length > 0)) {
            props.saveGeofenceDevices({ changedDevices: changedDevices || [], changedGroups: changedGroups || [] });
        }
    };

    return (
        <Container>
            {/*<pre>{JSON.stringify(props.devices.devices, null, 2)}</pre>*/}
            <Menu pointing secondary>
                <Menu.Item
                    name='devices'
                    active={activeItem === 'devices'}
                    onClick={handleMenuClick}
                />
                <Menu.Item
                    name='groups'
                    active={activeItem === 'groups'}
                    onClick={handleMenuClick}
                    disabled={false}
                />
            </Menu>
            {activeItem === 'devices' ?
                <List style={{height: '200px', maxHeight: '200px', overflow: 'auto'}} divided verticalAlign='middle'
                      relaxed='very'>{deviceList}</List> : null}
            {activeItem === 'groups' ?
                <List style={{height: '200px', maxHeight: '200px', overflow: 'auto'}} divided verticalAlign='middle'
                      relaxed='very'>{groupsList}</List> : null}
            <Divider/>
            <Grid textAlign='center' stackable columns='equal' padded='vertically'>
                <Grid.Row>
                    <Grid.Column>&nbsp;</Grid.Column>
                    <Grid.Column>&nbsp;</Grid.Column>
                    <Grid.Column>
                        <Button color='grey'
                                onClick={() => props.cancel()}>Cancel</Button>
                        <Button color='pink'
                                loading={props.geofences.isSavingGeofenceDevices}
                                onClick={() => {
                                    assignDevices();
                                }}>Assign</Button>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        devices: state.devices,
        geofences: state.geofences,
        groups: state.groups
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveGeofenceDevices: (payload) => dispatch(saveGeofenceDevices(payload)),
        geofencesDevicesGet: (payload) => dispatch(geofencesDevicesGet(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GeofenceAssignDevices);
