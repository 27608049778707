import React, {useLayoutEffect, useState} from 'react';
import { connect } from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import {Button, Icon, Container, Divider, Segment, List, Table, Grid, Header, Form} from 'semantic-ui-react';
import { authenticate } from "../../actions/auth";
import { userGet, childrenGet, updateUser } from "../../actions/user";
import { resetActioned } from "../../actions/ui";
import BaseModal from "../base-modal";
import EditEmail from "../edit-email";
import AddGroup from "../add-group";
import ChangePassword from "../change-password";
import EditSMS from "../edit-sms";
import EditContact from "../edit-contact";
import InviteUser from "../invite-user";
import {hasAccess} from "../../utils/protected-component/protected-component";

const ManageUser = (props) => {
    useLayoutEffect(() => {
        props.userGet();
        props.childrenGet();
    }, []);

    let [ modals, setModal ] = useState({});

    const executeAction = data => {
        // console.log(data);
        if(data.action) {
            setModal({ ...modals, [data.action]: true, data });
        }
    };

    const onCloseModal = () => {
        // props.clearErrors();
        props.resetActioned();
        setModal({});
    };

    return (
        <Container fluid>
            <Divider hidden />
            {/*-- User Info --*/}
            <Container>
                <Segment textAlign='left'>
                    <Divider hidden />
                    <Header as='h4' textAlign='center'>Account Information</Header>
                    <Divider hidden />
                    <Divider hidden />
                    <Container text>
                        <Grid columns={3}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h4' color='grey'>Email</Header>
                                </Grid.Column>
                                <Grid.Column>
                                    <p>{props.user.email}</p>
                                </Grid.Column>
                                <Grid.Column>
                                    <Header as='h4'>
                                        <Link
                                            to='#'
                                            onClick={
                                                () => executeAction({
                                                    action: 'email',
                                                    email: props.user.email
                                                    })
                                            }
                                        >Edit</Link>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h4' color='grey'>Password</Header>
                                </Grid.Column>
                                <Grid.Column>
                                    <Header as='h4'>
                                        <Link
                                            to='#'
                                            onClick={
                                                () => executeAction({
                                                    action: 'password'
                                                })
                                            }
                                        >Change Password</Link>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                    <Divider hidden />
                </Segment>
                <Segment textAlign='left'>
                    <Divider hidden />
                    <Header as='h4' textAlign='center'>SMS Alerts</Header>
                    <Divider hidden />
                    <Divider hidden />
                    <Container text>
                        <Grid columns={3}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h4' color='grey'>Alerts Number</Header>
                                </Grid.Column>
                                <Grid.Column>
                                    <p>{props.user.alertsNumber}</p>
                                </Grid.Column>
                                <Grid.Column>
                                    <Header as='h4'>
                                        <Link
                                            to='#'
                                            onClick={
                                                () => executeAction({
                                                    action: 'alerts'
                                                })
                                            }
                                        >Edit</Link></Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                    <Divider hidden />
                </Segment>
                <Segment textAlign='left'>
                    <Divider hidden />
                    <Header as='h4' textAlign='center'>Contact Information</Header>
                    <Header as='h4' textAlign='center'>
                        <Link
                            to='#'
                            onClick={
                                () => executeAction({
                                    action: 'contact'
                                })
                            }
                        >Edit</Link>
                    </Header>
                    <Divider hidden />
                    <Divider hidden />
                    <Container text>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h4' color='grey'>Your Name</Header>
                                </Grid.Column>
                                <Grid.Column>
                                    <p>{props.user.firstName} {props.user.lastName}</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h4' color='grey'>Company</Header>
                                </Grid.Column>
                                <Grid.Column>
                                    <p>{props.user.companyName}</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h4' color='grey'>Address</Header>
                                </Grid.Column>
                                <Grid.Column>
                                    <List>
                                        {props.user.address1 ? <List.Item>{props.user.address1 + ', '}</List.Item> : null}
                                        {props.user.address2 ? <List.Item>{props.user.address2 + ', '}</List.Item> : null}
                                        {props.user.address3 ? <List.Item>{props.user.address3 + ', '}</List.Item> : null}
                                        {props.user.address4 ? <List.Item>{props.user.address4 + ', '}</List.Item> : null}
                                        {props.user.postcode ? <List.Item>{props.user.postcode + ', '}</List.Item> : null}
                                        {props.user.country ? <List.Item>{props.user.country}</List.Item> : null}
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h4' color='grey'>Contact Number</Header>
                                </Grid.Column>
                                <Grid.Column>
                                    <p>{props.user.contactNumber}</p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                    <Divider hidden />
                </Segment>
            </Container>
            <Divider hidden />
            <BaseModal
                color='blue'
                content='Edit'
                icon='edit'
                labelPosition='left'
                title='Edit Email Address'
                open={modals.email}
                onClose={onCloseModal}
            >
                <EditEmail
                    value={props.user.email}
                    submit={props.userUpdate}
                    icon='edit'
                    title='Edit Email Address'
                    buttonText='Update Email'
                    color='blue'
                    userId={props.user._id}
                    error={props.user.error}
                    loading={props.user.isUpdatingUser}
                    close={() => {
                        setModal({ ...modals, email: false });
                        // onCloseModal();
                    }}
                />
            </BaseModal>
            <BaseModal
                color='blue'
                content='Edit'
                icon='lock'
                labelPosition='left'
                title='Change Password'
                open={modals.password}
                onClose={onCloseModal}
            >
                <ChangePassword
                    submit={props.userUpdate}
                    icon='lock'
                    title='Change Password'
                    buttonText='Change Password'
                    color='blue'
                    userId={props.user._id}
                    error={props.user.error}
                    loading={props.user.isUpdatingUser}
                    close={() => {
                        setModal({ ...modals, password: false });
                        // onCloseModal();
                    }}
                />
            </BaseModal>
            <BaseModal
                color='blue'
                content='Edit'
                icon='mobile alternate'
                labelPosition='left'
                title='Edit Alerts Number'
                open={modals.alerts}
                onClose={onCloseModal}
            >
                <EditSMS
                    value={props.user.alertsNumber}
                    submit={props.userUpdate}
                    icon='edit'
                    title='Edit Alerts Number'
                    buttonText='Update Number'
                    color='blue'
                    userId={props.user._id}
                    error={props.user.error}
                    loading={props.user.isUpdatingUser}
                    close={() => {
                        setModal({ ...modals, alerts: false });
                        // onCloseModal();
                    }}
                />
            </BaseModal>
            <BaseModal
                color='blue'
                content='Edit'
                icon='user'
                labelPosition='left'
                title='Edit Contact Information'
                open={modals.contact}
                onClose={onCloseModal}
            >
                <EditContact
                    user={props.user}
                    submit={props.userUpdate}
                    icon='edit'
                    title='Edit Contact Details'
                    buttonText='Update Details'
                    color='blue'
                    userId={props.user._id}
                    isParent={props.user.isParent}
                    error={props.user.error}
                    loading={props.user.isUpdatingUser}
                    close={() => {
                        setModal({ ...modals, contact: false });
                        // onCloseModal();
                    }}
                />
            </BaseModal>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        userGet: () => dispatch(userGet()),
        childrenGet: () => dispatch(childrenGet()),
        userUpdate: (payload) => dispatch(updateUser(payload)),
        resetActioned: () => dispatch(resetActioned())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
